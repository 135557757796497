<template>
  <section class="tables">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Banner  Management </h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link class="btn btn btn-add btn-primary" :to="`/website-management/add-banners`"
            >Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Banner</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>Main Banner</td>
                  <td>Clothing Made for You!</td>
                  <td><img src="https://shoplattice-website.codestager.com/images/home/sliders/slide-1.jpg"> </td>
                 
                  <td>
                    <label class="switch">
                      <input
                        type="checkbox"
                         />
                      <span class="slider round"></span> </label
                    ><br /><br />
                  </td>
                  <td>
                    <router-link
                      class="mr-2 table-icon"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="`/website-management/edit-banners/`"
                    >
                      <i class="mdi mdi-pencil"></i>
                    </router-link>
                    <a   class="mr-2 table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
export default {
  name: "banner",
  components: {
    Loader,
  },
  data() {
    return {
      showLoader: false,
      banner:""
    };
  },
  mounted() {
    this.getBanners();
  },
  methods: {
    async getBanners() {
      this.showLoader = true;
      const { data } = await setingsApi.getBanner();
      this.banner = data.data.data;
      console.log(this.banner);
      this.showLoader = false;
    },
  },
};
</script>

<style scoped></style>


